import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getReceiverDevices = (searchkey, receiverType, page, user_id) => {
  let queryString = '';
  if(searchkey != '' && receiverType != '')
  {
    queryString += `&receiver_device_name=${searchkey}&receiver_device_id=${searchkey}&receiver_device_type=${receiverType}`; 
  }
  else if(searchkey != '' )
  {
    queryString += `&receiver_device_name=${searchkey}&receiver_device_id=${searchkey}`; 
  }
  else if(receiverType != '' )
  {
    queryString += `&receiver_device_type=${receiverType}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/receiverdevice?apiAction=getReceiverDevices${queryString}&limit=${config.receiverdevices.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/receiverdevice?apiAction=getReceiverDevices${queryString}&limit=${config.receiverdevices.resultsPerPage}&page=${page}`, {})
    .then(response => {
      return response.data
    })
  }

}

const createReceiverDevice = (created_by, receiverdevicename, receiverdeviceid, receiverdevicetype, receiverdevicenotes,  receiverdevicestatus) => {
	return axios.post(`${apiUrl}/v1/receiverdevice`, {
    receiver_device_name: receiverdevicename,
    receiver_device_id: receiverdeviceid,
    receiver_device_type: receiverdevicetype,
    receiver_device_notes: receiverdevicenotes,
    receiver_device_status: receiverdevicestatus,
    created_by: created_by
  })
}

const updateReceiverDeviceDetails = (receiverdeviceId, receiverdevicename, receiverdeviceid, receiverdevicetype, receiverdevicenotes,receiverdevicestatus) => {
	return axios.patch(`${apiUrl}/v1/receiverdevice/${receiverdeviceId}`, {
    receiver_device_name: receiverdevicename,
    receiver_device_id: receiverdeviceid,
    receiver_device_type: receiverdevicetype,
    receiver_device_notes: receiverdevicenotes,
    receiver_device_status: receiverdevicestatus
  })
}

const deleteReceiverDevice = (receiverdeviceId) => {
	return axios.delete(`${apiUrl}/v1/receiverdevice/${receiverdeviceId}`, {})
}

const receiverFilter = ( searchkey,receiverType, page, user_id) => {
  let queryString = '';
  if(searchkey != '' && receiverType != '')
  {
    queryString += `&receiver_device_name=${searchkey}&receiver_device_id=${searchkey}&receiver_device_type=${receiverType}`; 
  }
  else if(searchkey != '' )
  {
    queryString += `&receiver_device_name=${searchkey}&receiver_device_id=${searchkey}`; 
  }
  else if(receiverType != '' )
  {
    queryString += `&receiver_device_type=${receiverType}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/receiverdevice?apiAction=getReceiverDevices${queryString}&limit=${config.projects.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/receiverdevice?apiAction=getReceiverDevices${queryString}&limit=${config.projects.resultsPerPage}&page=${page}`, {})
    .then(response => {
      return response.data
    })
  }
}

export const receiverdeviceService = {
  getReceiverDevices,
  createReceiverDevice,
  updateReceiverDeviceDetails,
  deleteReceiverDevice,
  receiverFilter
}