import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getReportSettings = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&report_name=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/report-settings?apiAction=getReportSettings${queryString}&limit=${config.devicereports.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/report-settings?apiAction=getReportSettings${queryString}&limit=${config.devicereports.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}
const reportSettingsFilter = (searchkey,page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&report_name=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/report-settings?apiAction=getReportSettings${queryString}&limit=${config.devicereports.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/report-settings?apiAction=getReportSettings${queryString}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}
const getReportSettingById = ( report_setting_id ) => {
  return axios.get(`${apiUrl}/v1/report-settings/${report_setting_id}`, {})
  .then(response => {
    return response.data
  })
}

const createReportSetting = (user_id, report_type, selected_devices, report_name, report_frequency, time_zone, first_report_on, reciver_email,timeZone , fromTime, toTime, reportMailTime) => {
  console.log("-------.",timeZone)
	return axios.post(`${apiUrl}/v1/report-settings`, {
    created_by: user_id,
    report_type: report_type,
    selected_devices: selected_devices,
    report_name: report_name,
    report_frequency: report_frequency,
    time_zone: time_zone,
    first_report_on: first_report_on,
    reciver_email: reciver_email,
    timeZone:timeZone , 
    fromTime : fromTime,
    toTime: toTime,
    report_email_time:reportMailTime
  })
}

const updateReportSetting = (report_setting_id, user_id, report_type, selected_devices, report_name, report_frequency, time_zone, first_report_on, reciver_email ,timeZone , fromTime, toTime, reportMailTime) => {
	return axios.patch(`${apiUrl}/v1/report-settings/${report_setting_id}`, {
    created_by: user_id,
    report_type: report_type,
    selected_devices: selected_devices,
    report_name: report_name,
    report_frequency: report_frequency,
    time_zone: time_zone,
    first_report_on: first_report_on,
    reciver_email: reciver_email,
    timeZone:timeZone,
    fromTime : fromTime,
    toTime: toTime,
    report_email_time:reportMailTime
  })
}

const deleteReportSetting = ( report_setting_id ) => {
  return axios.delete(`${apiUrl}/v1/report-settings/${report_setting_id}`)
}

export const reportsettings = {
  getReportSettings,
  getReportSettingById,
  createReportSetting,
  updateReportSetting,
  deleteReportSetting,
  reportSettingsFilter
}