import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getReceiverTypes = (searchkey, page, user_id) => {
  let queryString = '';

  if(searchkey != '')
  {
    queryString += `&receiver_type_name=${searchkey}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/receivertype?apiAction=getReceiverTypes${queryString}&limit=${config.receivertypes.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
      .then(response => {
        return response.data
      })
    }else{
    return axios.get(`${apiUrl}/v1/receivertype?apiAction=getReceiverTypes${queryString}&limit=${config.receivertypes.resultsPerPage}&page=${page}`, {})
      .then(response => {
        return response.data
      })
    }

}


const receiverTypesFilter = ( searchkey,page, user_id) => {
  let queryString = '';

  if(searchkey != '')
  {
    queryString += `&receiver_type_name=${searchkey}`; 
  }

	if(user_id !== undefined){
   return axios.get(`${apiUrl}/v1/receivertype?apiAction=getReceiverTypes${queryString}&limit=${config.receivertypes.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
   return axios.get(`${apiUrl}/v1/receivertype?apiAction=getReceiverTypes${queryString}&limit=${config.receivertypes.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const getReceiverTypesAll = (user_id) => {
	if(user_id !== undefined){
   return axios.get(`${apiUrl}/v1/receivertype/all?&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
   return axios.get(`${apiUrl}/v1/receivertype/all`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const createReceiverType = (created_by, receivertypename, receivertypenotes,  receivertypestatus) => {
	return axios.post(`${apiUrl}/v1/receivertype`, {
    receiver_type_name: receivertypename,
    receiver_type_notes: receivertypenotes,
    receiver_type_status: receivertypestatus,
    created_by: created_by
  })
}

const updateReceiverTypeDetails = (receivertypeId, receivertypename, receivertypenotes,receivertypestatus) => {
	return axios.patch(`${apiUrl}/v1/receivertype/${receivertypeId}`, {
    receiver_type_name: receivertypename,
    receiver_type_notes: receivertypenotes,
    receiver_type_status: receivertypestatus
  })
}

const deleteReceiverType = (receivertypeId) => {
	return axios.delete(`${apiUrl}/v1/receivertype/${receivertypeId}`, {})
}

export const receivertypeService = {
  getReceiverTypes,
  createReceiverType,
  updateReceiverTypeDetails,
  deleteReceiverType,
  getReceiverTypesAll,
  receiverTypesFilter,
}