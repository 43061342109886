import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getTemperatures = (searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/temperature?apiAction=getTemperature${queryString}&limit=${config.temperature_as.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/temperature?apiAction=getTemperature${queryString}&limit=${config.temperature_as.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllTemperatures = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/alarm-settings/temperature/all?created_by=${user_id}`, {}).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/alarm-settings/temperature/all`).then((response) => {
			return response.data
		})
	}
}

const createTemperature = ( created_by, alarm_name, low_temp_warn_threshold, high_temp_warn_threshold, low_temp_threshold, high_temp_threshold,  effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.post(`${apiUrl}/v1/alarm-settings/temperature`, {
    alarm_name: alarm_name,
    low_temp_warn_threshold: low_temp_warn_threshold,
    high_temp_warn_threshold: high_temp_warn_threshold,
    low_temp_threshold: low_temp_threshold,
    high_temp_threshold: high_temp_threshold,
    
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    created_by: created_by,
    effective_days: effective_days,
  })
}

const updateTemperature = ( temp_as_id, alarm_name, low_temp_warn_threshold, high_temp_warn_threshold, low_temp_threshold, high_temp_threshold, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.patch(`${apiUrl}/v1/alarm-settings/temperature/${temp_as_id}`, {
    alarm_name: alarm_name,
    low_temp_warn_threshold: low_temp_warn_threshold,
    high_temp_warn_threshold: high_temp_warn_threshold,
    low_temp_threshold: low_temp_threshold,
    high_temp_threshold: high_temp_threshold,
    
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    effective_days: effective_days,
    updated_at: new Date(),
  })
}

const deleteTemperature = ( temp_as_id ) => {
  return axios.delete(`${apiUrl}/v1/alarm-settings/temperature/${temp_as_id}`)
}

const temperatureFilter = ( searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/temperature?apiAction=getTemperature${queryString}&limit=${config.temperature_as.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/temperature?apiAction=getTemperature${queryString}&limit=${config.temperature_as.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

const getTempratureById = (temperature_as_id) => {
	return axios.get(`${apiUrl}/v1/alarm-settings/temperature/${temperature_as_id}`).then((response) => {
		return response.data
	})
}


export const temperatureService = {
  getTemperatures,
  getAllTemperatures,
  createTemperature,
  updateTemperature,
  deleteTemperature,
  temperatureFilter,getTempratureById,
}