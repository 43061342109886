import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url


  const getNotificationList = async ( device_created_by) => {
    if(device_created_by !== undefined){
        return axios.get(`${apiUrl}/v1/notification?device_created_by=${device_created_by}`, {})
        .then(response => {
          return response.data
        })
    }else{
     return axios.get(`${apiUrl}/v1/notification`, {})
      .then(response => {
        return response.data
      })
    }
  }


  const getNotifications = async (page, user_id) => {
    if(user_id !== undefined){
      return axios.get(`${apiUrl}/v1/notification/all?limit=${config.notificationlist.resultsPerPage}&page=${page}&device_created_by=${user_id}&sortBy=created_at:desc`, {})
      .then(response => {
        return response.data
      })
    }else{
      return axios.get(`${apiUrl}/v1/notification/all?limit=${config.notificationlist.resultsPerPage}&page=${page}&sortBy=created_at:desc`, {})
      .then(response => {
        return response.data
      })
    }
  }

  const updateViewNotification = async ( notification_id) => {
    return axios.patch(`${apiUrl}/v1/notification/${notification_id}`)
  }
  
  
const delete_Notification = ( user_id ) => {
  return axios.delete(`${apiUrl}/v1/notification/${user_id}`)
}


  export const Notification_service = {
    getNotificationList,
    updateViewNotification,
    getNotifications,
    delete_Notification
  }