import axios from "axios";
import { config } from "../assets/config/config";
const apiUrl = config.api.url;

const getUsers = async (searchkey, page, user_id) => {
  let queryString = "";

  if (searchkey != "") {
    queryString += `&name=${searchkey}&email=${searchkey}&phone_number=${searchkey}&company_name=${searchkey}`;
  }

  if (user_id !== undefined) {
    return axios
      .get(
        `${apiUrl}/v1/users?apiAction=getUsers${queryString}&limit=${config.users.resultsPerPage}&page=${page}&created_by=${user_id}`,
        {}
      )
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .get(
        `${apiUrl}/v1/users?apiAction=getUsers${queryString}&limit=${config.users.resultsPerPage}&page=${page}`,
        {}
      )
      .then((response) => {
        return response.data;
      });
  }
};

const getusersForEndroseAssginment = async (page, user_id) => {
  return axios
    .get(
      `${apiUrl}/v1/users/get-users-for-assignEndorsement?limit=${config.users.resultsPerPage}&page=${page}&endorsement_created_by=${user_id}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

const getUsersByActiveStatus = (active_status) => {
  return axios
    .get(
      `${apiUrl}/v1/users/get-users-by-active-status?active_status=${active_status}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

const getUsersByIsLoggedIn = (isLoggedIn) => {
  return axios
    .get(
      `${apiUrl}/v1/users/get-users-by-is-logged-in?is_logged_in=${isLoggedIn}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

const getroleUser = (user_id, user_role) => {
  return axios.post(`${apiUrl}/v1/users/roleuser`, {
    user_id: user_id,
    user_role: user_role,
  });
};

const createUser = ( created_by, username, email, company_name, phone_number, device_create_limit,  password, role , device_location, google_location, send_sms, send_call, free_email_limit, free_sms_limit, free_call_limit, back_to_normal_email, back_to_normal_sms, back_to_normal_call ) => {
	return axios.post(`${apiUrl}/v1/users`, {
    name: username,
    email: email,
    company_name: company_name,
    phone_number: phone_number,
    device_create_limit: device_create_limit,
    password: password,
    role: role,
    device_location: device_location,
    created_by: created_by,
    google_location: google_location,
    send_sms: send_sms,
    send_call: send_call,
    free_email_limit: free_email_limit,
    free_sms_limit: free_sms_limit,
    free_call_limit: free_call_limit,
    back_to_normal_email: back_to_normal_email,
    back_to_normal_sms: back_to_normal_sms,
    back_to_normal_call: back_to_normal_call
  })
}

const updateUserPassword = (userId, old_password, password) => {
  return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    old_password: old_password,
    password: password,
  });
};

const updateUserEmail = (userId, email) => {
  return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    email: email,
  });
};

const activateUser = (userId, device_create_limit, active_status) => {
  // Get the current date
  var currentDate = new Date();

  // Add 365 days to the current date
  var futureDate = new Date(currentDate);
  futureDate.setFullYear(currentDate.getFullYear() + 1);
  return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    active_status: active_status,
    device_create_limit: device_create_limit,
    activated_at: new Date(),
    expired_at: futureDate,
  });
};

const updateUserPhone = (userId, phone_number) => {
  return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    phone_number: phone_number,
  });
};

const verifyEmailOtp = (userId, email_otp) => {
  return axios.post(`${apiUrl}/v1/users/verify-otp/${userId}`, {
    email_otp: email_otp,
  });
};

const verifyPhoneOtp = (userId, phone_otp) => {
  return axios.post(`${apiUrl}/v1/users/verify-otp/${userId}`, {
    phone_otp: phone_otp,
  });
};

const updateUserDetails = (userId, username, company_name, phone_number, device_create_limit, role, device_location, google_location, send_sms, send_call, emailchecked, statuschecked, free_email_limit, free_sms_limit, free_call_limit, back_to_normal_email, back_to_normal_sms, back_to_normal_call, expired_at) => {
	return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    name: username,
    company_name: company_name,
    phone_number: phone_number,
    device_create_limit: device_create_limit,
    role: role,
    device_location: device_location,
    google_location: google_location,
    send_sms: send_sms,
    send_call: send_call,
    isEmailVerified: emailchecked,
    active_status: statuschecked,
    free_email_limit: free_email_limit,
    free_sms_limit: free_sms_limit,
    free_call_limit: free_call_limit,
    back_to_normal_email: back_to_normal_email,
    back_to_normal_sms: back_to_normal_sms,
    back_to_normal_call: back_to_normal_call,
    expired_at: expired_at
  })
}

const updateinovice_acknowledgement = (userId) => {
  return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    inovice_acknowledgement_on: new Date(),
  });
};

const deleteUser = (userId) => {
  return axios.delete(`${apiUrl}/v1/users/${userId}`, {});
};

const getUsersByNameEmail = (searchkey, page, user_id) => {
  let queryString = "";

  if (searchkey != "") {
    queryString += `&name=${searchkey}&email=${searchkey}&phone_number=${searchkey}&company_name=${searchkey}`;
  }

  if (user_id !== undefined) {
    return axios
      .get(
        `${apiUrl}/v1/users?apiAction=getUsers${queryString}&limit=${config.users.resultsPerPage}&page=${page}&created_by=${user_id}`,
        {}
      )
      .then((response) => {
        return response.data;
      });
  } else {
    return axios
      .get(
        `${apiUrl}/v1/users?apiAction=getUsers${queryString}&limit=${config.users.resultsPerPage}&page=${page}`,
        {}
      )
      .then((response) => {
        return response.data;
      });
  }
};

const getUserById = (userId) => {
  return axios.get(`${apiUrl}/v1/users/${userId}`, {}).then((response) => {
    return response.data;
  });
};

const userVerifyOnsign = async (email, password) => {
  return axios
    .post(`${apiUrl}/v1/auth/user-verify-sign`, {
      email: email,
      password: password,
    })
    .then((response) => {
      return response.data;
    });
};

const getAllUsers = (user_id) => {
  if (user_id !== undefined) {
    return axios
      .get(`${apiUrl}/v1/users/all?created_by=${user_id}`)
      .then((response) => {
        return response.data;
      });
  } else {
    return axios.get(`${apiUrl}/v1/users/all`).then((response) => {
      return response.data;
    });
  }
};

const getNormalUsers = () => {
  return axios.get(`${apiUrl}/v1/users/normal-users`).then((response) => {
    return response.data;
  });
};

const GenerateInvoice = (user_id, fromDate, toDate) => {
  return axios.post(`${apiUrl}/v1/users/generate-invoice`, {
    user_id: user_id,
    fromDate: fromDate,
    toDate: toDate,
  });
};

export const userService = {
  getUsers,
  getUsersByActiveStatus,
  getUsersByIsLoggedIn,
  getroleUser,
  createUser,
  updateUserPassword,
  activateUser,
  updateUserEmail,
  updateUserPhone,
  verifyEmailOtp,
  verifyPhoneOtp,
  updateUserDetails,
  deleteUser,
  getUsersByNameEmail,
  getUserById,
  userVerifyOnsign,
  getAllUsers,
  getNormalUsers,
  updateinovice_acknowledgement,
  getusersForEndroseAssginment,
  GenerateInvoice,
};
