import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getProjects = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&project_name=${searchkey}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-projects?apiAction=getProjects${queryString}&limit=${config.projects.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-projects?apiAction=getProjects${queryString}&limit=${config.projects.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllProjects = async (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/device-projects/all?created_by=${user_id}`).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/device-projects/all`).then((response) => {
			return response.data
		})
	}
}

const createProject = ( created_by, project_name, project_location, project_notes, project_status ) => {
  return axios.post(`${apiUrl}/v1/device-projects`, {
    project_name: project_name,
    project_location: project_location,
    project_notes: project_notes,
    project_status: project_status,
    created_by: created_by
  })
}

const updateProject = ( project_id, project_name, project_location, project_notes, project_status ) => {
  return axios.patch(`${apiUrl}/v1/device-projects/${project_id}`, {
    project_name: project_name,
    project_location: project_location,
    project_notes: project_notes,
    project_status: project_status
  })
}

const deleteProject = ( project_id ) => {
  return axios.delete(`${apiUrl}/v1/device-projects/${project_id}`)
}

const projectFilter = ( searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&project_name=${searchkey}`; 
  }
  
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-projects?apiAction=getProjects${queryString}&limit=${config.projects.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-projects?apiAction=getProjects${queryString}&limit=${config.projects.resultsPerPage}&page=${page}`, {})
    .then(response => {
      return response.data
    })
  }
}

export const projectService = {
  getProjects,
  getAllProjects,
  createProject,
  updateProject,
  deleteProject,
  projectFilter
}