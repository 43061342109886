import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

// const getDownloadReports = (page, resultsPerPage, user_id) => {
// 	if(user_id !== undefined){
//     console.log('getDownloadReports called');

//     return axios.get(`${apiUrl}/v1/download-reports?limit=${resultsPerPage}&page=${page}&userId=${user_id}&sortBy=created_at:desc`, {})
// 		.then(response => {
//       console.log('getDownloadReports response.data'.response.data)
// 	    return response.data
// 	  })
//   }else{
//     console.log('getDownloadReports called');

//     return axios.get(`${apiUrl}/v1/download-reports?limit=${resultsPerPage}&page=${page}&sortBy=created_at:desc`, {})
// 		.then(response => {
//       console.log('getDownloadReports response.data'.response.data)
// 	    return response.data
// 	  })
//   }
// }

const getDownloadReports = async( data) => {
  return await axios.post(`${apiUrl}/v1/download-reports/getDownloadReports`, data)
		.then(response => {
	    return response.data
	  });

}

const getDownloadReportsFilter = async( data) => {
  return await axios.post(`${apiUrl}/v1/download-reports/getDownloadReportsFilter`, data)
		.then(response => {
	    return response.data
	  });

}


const createDownloadReport = async( data) => {
  return await axios.post(`${apiUrl}/v1/download-reports/create`, data)
		.then(response => {
      console.log('response',response.data);
	    return response.data
	  });

}

const getMatchingDevices = async( data) => {
	return axios.get(`${apiUrl}/v1/download-reports/deviceByCriteria`, {params: data})
		.then(response => {
			console.log('response',response.data);
			return response.data
		});
}

const generateReport = async( data) => {
	return axios.post(`${apiUrl}/v1/download-reports/create`, data)
		.then(response => {
			console.log('response',response.data);
			return response.data
		});
}


const retryOnDemandReport = (ondemand_report_id) => {
    return axios.put(`${apiUrl}/v1/download-reports/retry/${ondemand_report_id}`, {ondemand_report_id})
		.then(response => {
	    return response.data
	})
}

export const downloadreportService = {
  getDownloadReports,
  getDownloadReportsFilter,
  createDownloadReport,
  getMatchingDevices,
  generateReport,
  retryOnDemandReport
}
