import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getUserRoles = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&role_name=${searchkey}`; 
  }
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/user-roles?apiAction=getUserRoles${queryString}&limit=${config.roles.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/user-roles?apiAction=getUserRoles${queryString}&limit=${config.roles.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllUserRoles = (user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/user-roles/all?created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/user-roles/all`, {})
    .then(response => {
      return response.data
    })
  }
}

const getUserRoleByID = ( user_role_id ) => {
  return axios.get(`${apiUrl}/v1/user-roles/${user_role_id}`, {})
  .then(response => {
    return response.data
  })
}

const createUserRole = ( user_id, role_name, status, user_permissions ) => {
  return axios.post(`${apiUrl}/v1/user-roles`, {
    role_name: role_name,
    status: status,
    user_permissions: user_permissions,
    created_by: user_id
  })  
}

const updateUserRole = ( user_role_id, role_name, status, user_permissions ) => {
  return axios.patch(`${apiUrl}/v1/user-roles/${user_role_id}`, {
    role_name: role_name,
    status: status,
    user_permissions: user_permissions,
    updated_at: new Date()
  })
}

const deleteUserRole = ( user_role_id ) => {
  return axios.delete(`${apiUrl}/v1/user-roles/${user_role_id}`)
}

const userrolesFilter = ( searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&role_name=${searchkey}`; 
  }
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/user-roles?apiAction=getUserRoles${queryString}&limit=${config.roles.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/user-roles?apiAction=getUserRoles${queryString}&limit=${config.roles.resultsPerPage}&page=${page}`, {})
    .then(response => {
      return response.data
    })
  }
}

export const user_rolesService = {
  getUserRoles,
  getAllUserRoles,
  getUserRoleByID,
  createUserRole,
  updateUserRole,
  deleteUserRole,
  userrolesFilter
}