import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getEmailNotifications = (page) => {
	return axios.get(`${apiUrl}/v1/email-notifications?limit=${config.email_notifications.resultsPerPage}&page=${page}`, {}).then((response) => {
		return response.data
	})
}

const sendEmailNotifications = (users, email_subject, email_content) => {
	return axios.post(`${apiUrl}/v1/email-notifications`, {
		users: users,
		email_subject: email_subject,
		email_content: email_content,
	})
}

export const emailNotificationService = {
	getEmailNotifications,
	sendEmailNotifications,
}
