
import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url;

const addDeviceData = (created_by, device_id, trade) =>{
     return axios.post(`${apiUrl}/v1/FDACFR/Device`,{
      device_id:device_id,
      trade:trade,
      created_by:created_by
     })
}


const UpdateDeviceData = (CFRDeviceID, device_id, trade) =>{
  return axios.patch(`${apiUrl}/v1/FDACFR/Device/${CFRDeviceID}`,{
   device_id:device_id,
   trade:trade,
  })
}





const getCFRDevice = async(page, user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/FDACFR/Device?limit=${config.projects.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/FDACFR/Device?limit=${config.projects.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const set_21crf = (userID,crf21_value) =>{
  return axios.post(`${apiUrl}/v1/FDACFR/crf21_statusChange`,
  {userId:userID,
  crf21_value:crf21_value})
}

const addEndorsement = (created_by, endorsement_name, description) =>{
  return axios.post(`${apiUrl}/v1/FDACFR/endorsement`,{
    endorsement_name:endorsement_name,
    description:description,
    created_by:created_by
  })
}






const getEndorsement = async(page, user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/FDACFR/endorsement?limit=${config.projects.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/FDACFR/endorsement?limit=${config.projects.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getEndorsementByUserId = async(user_id) =>
{
  return axios.post(`${apiUrl}/v1/FDACFR/endorsement/ByUserId`,{
    user_id:user_id
  })
}

const updateEndorsement = async(endorsementId,endorsement_name, description)  =>
{
    return axios.patch(`${apiUrl}/v1/FDACFR/endorsement/${endorsementId}`,{
      endorsement_name:endorsement_name,
      description:description,
    })
}


const updateSelectAsgEn = async(user_id,selecEndorsementData)  =>
{
    return axios.post(`${apiUrl}/v1/FDACFR/endorsement/updateSelectAssignEndorsement`,{
      user_id:user_id,
      selecEndorsementData:selecEndorsementData,
    })
}

const deleteEndorsement = ( endorsementId ) => {
  return axios.delete(`${apiUrl}/v1/FDACFR/endorsement/${endorsementId}`)
}


const updateSign = async(CFRdeviceId,signStatus,userId)=>
{
  console.log("signStatus",signStatus)
  return axios.patch(`${apiUrl}/v1/FDACFR/signupdate/${CFRdeviceId}`,{
    signed:!signStatus,
    signedBy:userId
  })
}



const deleteCFRDevice = async (CFRDeviceID) => {

  return axios.delete(`${apiUrl}/v1/FDACFR/Device/${CFRDeviceID}`)

  
}



export const FDACFRservice =
{
  addDeviceData,
  set_21crf,
  addEndorsement,
  getEndorsement,
  updateEndorsement,
  deleteEndorsement,
  getEndorsementByUserId,
  updateSelectAsgEn,
  getCFRDevice,
  updateSign,deleteCFRDevice,UpdateDeviceData
}