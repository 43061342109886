import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getHomePage = (user_id,device_location) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/home-page?created_by=${user_id}&device_location_id=${device_location}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/home-page`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const getCalibrationData = (calibration_id,page,user_id,device_location) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/home-page/calibration?limit=${config.calibrationlist.resultsPerPage}&page=${page}&calibration_id=${calibration_id}&created_by=${user_id}&device_location_id=${device_location}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    console.log("admins")
    return axios.get(`${apiUrl}/v1/home-page/calibration?limit=${config.calibrationlist.resultsPerPage}&page=${page}&calibration_id=${calibration_id}`, {})
		.then(response => {
	    return response.data
	  })
  }
}
export const dashboardService = {
  getHomePage,
  getCalibrationData
}