import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import ForwardRoute from './components/Routes/ForwardRoute'

const Layout = lazy(() => import('./containers/Layout'))
const Auth = lazy(() => import('./containers/Auth'))
const CMSPolicy = lazy(() => import('./containers/CMSPolicy'))
const Landing = lazy(() => import('./pages/Landing'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))
const RealTimeFullView = lazy(() => import('./pages/DeviceMonitoring/RealTimeFullView.js'))


function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <ForwardRoute path="/auth" component={Auth} />
          <Route exact path="/view-cms/:id" component={CMSPolicy} />
          {/* Place new routes over this */}
          <ProtectedRoute path="/app" component={Layout} />
          <Route exact path="/verify-email" component={EmailVerification} />
          <Route exact path="/auth/login" component={Landing} />
          <Route exact path="/device-monitoring/realtime/full-view" component={RealTimeFullView} />

          <Redirect from="*" to="/auth/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
