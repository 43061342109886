import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getCms = async (page, user_id) => {
    if (user_id !== undefined) {
        return axios.get(`${apiUrl}/v1/cms?limit=${config.users.resultsPerPage}&page=${page}&created_by=${user_id}`, {}).then((response) => {
            return response.data
        })
    } else {
        return axios.get(`${apiUrl}/v1/cms?limit=${config.users.resultsPerPage}&page=${page}`, {}).then((response) => {
            return response.data
        })
    }
}


const createCms = (created_by, title, url_index, meta_keyword, meta_description, cms_type, cms_status, content) => {
    return axios.post(`${apiUrl}/v1/cms`, {
        title: title,
        url_index: url_index,
        meta_keyword: meta_keyword,
        meta_description: meta_description,
        cms_type: cms_type,
        cms_status: cms_status,
        created_by: created_by,
        content: content
    })
}


const getCmsById = (cms_id) => {
    return axios.get(`${apiUrl}/v1/cms/${cms_id}`, {})
        .then(response => {
            return response.data
        })
}


const updateCms = (cms_id, title, url_index, meta_keyword, meta_description, cms_type, cms_status, content) => {
    return axios.patch(`${apiUrl}/v1/cms/${cms_id}`, {
        title: title,
        url_index: url_index,
        meta_keyword: meta_keyword,
        meta_description: meta_description,
        cms_type: cms_type,
        cms_status: cms_status,
        content: content
    })
}

const deleteCms = (cms_id) => {
    return axios.delete(`${apiUrl}/v1/cms/${cms_id}`)
}
const getCmsbyIndexURL = (cmsIndex) => {
    return axios.get(`${apiUrl}/v1/cms/cms-policy/${cmsIndex}`, {})
        .then(response => {
            return response.data
        })
}

// const getcmsbyName = (cmsName) => {
//     return axios.get(`${apiUrl}/v1/cms/get-cms?cms_name=${cmsName}`, {}).then((response) => {
//         return response.data
//     }) 
// }



export const cmsService = {
    getCms,
    createCms,
    getCmsById,
    updateCms,
    deleteCms,
    getCmsbyIndexURL
    // getcmsbyName
}

