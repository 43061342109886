import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getAllDeviceReports = (page, resultsPerPage, user_id,device_location) => {

	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-reports/all?apiAction=getAllDeviceReports&limit=${10000}&page=${page}&created_by=${user_id}&device_location=${[device_location]}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-reports/all?apiAction=getAllDeviceReports&limit=${10000}&page=${page}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getDeviceReports = (searchkey,locationfilter,groupfilter,modelfilter,projectfilter,page, resultsPerPage, user_id) => {

  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_id=${searchkey}&device_name=${searchkey}`; 
  }
  if(locationfilter != '')
  {
    queryString += `&device_location_id=${locationfilter}`; 
  }
  if(groupfilter != '')
  {
    queryString += `&device_group_id=${groupfilter}`; 
  }
  if(modelfilter != '')
  {
    queryString += `&device_model_id=${modelfilter}`; 
  }
  if(projectfilter != '')
  {
    queryString += `&device_project_id=${projectfilter}`; 
  }

	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getDeviceReports${queryString}&limit=${config.devicereports.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getDeviceReports${queryString}&limit=${config.devicereports.resultsPerPage}&page=${page}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllDeviceReportsByID = (page, device_id, user_id) => {
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-history?device_id=${device_id}&limit=${config.devicereports.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-history?device_id=${device_id}&limit=${config.devicereports.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getDeviceReportAll = (data) => {
  
    return axios.post(`${apiUrl}/v1/device-graphs`, data)
		.then(response => {
	    return response.data
	  })
}

const graphDeviceReportAll = (data) => {
  
  return axios.post(`${apiUrl}/v1/device-graphs/graphDeviceReportAll`, data)
  .then(response => {
    return response.data
  })
}

const getDevicelist = (user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-map-list?created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-map-list`, {})
    .then(response => {
      return response.data
    })
  }
}

const getDevicelistFitler = ( device_id,device_name,user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-map-list?created_by=${user_id}&device_id=${device_id}&device_name=${device_name}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-map-list?device_id=${device_id}&device_name=${device_name}`, {})
    .then(response => {
      return response.data
    })
  }
}

const deviceTracklist = (data) => {
  return axios.post(`${apiUrl}/v1/device-tracking-list`,data)
  .then(response => {
    return response.data
  })
}

const createDeviceReport = (created_by, devicemodelname, devicemodelnotes,  devicemodelstatus) => {
	return axios.post(`${apiUrl}/v1/device-model`, {
    device_model_name: devicemodelname,
    device_model_notes: devicemodelnotes,
    device_model_status: devicemodelstatus,
    created_by: created_by
  })
}

const updateDeviceReportDetails = (devicemodelId, devicemodelname, devicemodelnotes,devicemodelstatus) => {
	return axios.patch(`${apiUrl}/v1/device-model/${devicemodelId}`, {
    device_model_name: devicemodelname,
    device_model_notes: devicemodelnotes,
    device_model_status: devicemodelstatus
  })
}

const deleteDeviceReport = (devicemodelId) => {
	return axios.delete(`${apiUrl}/v1/device-report/${devicemodelId}`, {})
}

const devicereportsFilter = ( searchkey,locationfilter,groupfilter,modelfilter,projectfilter,userfilter, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_id=${searchkey}&device_name=${searchkey}`; 
  }
  if(locationfilter != '')
  {
    queryString += `&device_location_id=${locationfilter}`; 
  }
  if(groupfilter != '')
  {
    queryString += `&device_group_id=${groupfilter}`; 
  }
  if(modelfilter != '')
  {
    queryString += `&device_model_id=${modelfilter}`; 
  }
  if(projectfilter != '')
  {
    queryString += `&device_project_id=${projectfilter}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getDeviceReports${queryString}&device_created_by=${userfilter}&created_by=${user_id}&sortBy=device_log_time:desc&limit=${config.devicereports.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getDeviceReports${queryString}&device_created_by=${userfilter}&limit=${config.devicereports.resultsPerPage}&page=${page}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllDeviceReportsFilter = ( filter_key,locationfilter,groupfilter,modelfilter,projectfilter,userfilter, page, user_id) => {

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getAllDeviceReports&device_id=${filter_key}&device_name=${filter_key}&device_location_id=${locationfilter}&device_group_id=${groupfilter}&device_model_id=${modelfilter}&device_project_id=${projectfilter}&device_created_by=${userfilter}&created_by=${user_id}&sortBy=device_log_time:desc&limit=${1000}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-reports?apiAction=getAllDeviceReports&device_id=${filter_key}&device_name=${filter_key}&device_location_id=${locationfilter}&device_group_id=${groupfilter}&device_model_id=${modelfilter}&device_project_id=${projectfilter}&device_created_by=${userfilter}&limit=${1000}&page=${page}&sortBy=device_log_time:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const devicereportsDownload = async( data) => {
  return await axios.post(`${apiUrl}/v1/device-reports/downloadDeviceExcursion`, data)
		.then(response => {
	    return response.data
	  });
    
}

const getDeviceReportByDeviceId = ( device_id) => {
  
    return axios.post(`${apiUrl}/v1/device-reports/getReportListbyId`, {device_id:device_id})
		.then(response => {
	    return response.data
	  })
}

const getDownloadReports = async( data) => {
  return await axios.post(`${apiUrl}/v1/device-reports/getDownloadReports`, data)
		.then(response => {
	    return response.data
	  });
    
}

export const devicereportService = {
  getDeviceReports,
  getAllDeviceReportsByID,
  createDeviceReport,
  updateDeviceReportDetails,
  deleteDeviceReport,
  getDevicelist,
  getDeviceReportAll,
  devicereportsFilter,
  deviceTracklist,
  getDeviceReportByDeviceId,
  getDevicelistFitler,
  devicereportsDownload,
  getDownloadReports,
  graphDeviceReportAll,
  getAllDeviceReports,
  getAllDeviceReportsFilter
}