import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getDeviceModels = (searchkey, page, user_id) => {
	let queryString = '';
	if(searchkey != '')
	{
	  queryString += `&device_model_name=${searchkey}`; 
	}  

	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-model?apiAction=getModels${queryString}&limit=${config.devicemodels.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-model?apiAction=getModels${queryString}&limit=${config.devicemodels.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getDeviceModelsAll = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/device-model/all?created_by=${user_id}`).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/device-model/all`).then((response) => {
			return response.data
		})
	}
}

const createDeviceModel = (created_by, devicemodelname, devicemodelnotes,  devicemodelstatus) => {
	return axios.post(`${apiUrl}/v1/device-model`, {
    device_model_name: devicemodelname,
    device_model_notes: devicemodelnotes,
    device_model_status: devicemodelstatus,
    created_by: created_by
  })
}

const updateDeviceModelDetails = (devicemodelId, devicemodelname, devicemodelnotes,devicemodelstatus) => {
	return axios.patch(`${apiUrl}/v1/device-model/${devicemodelId}`, {
    device_model_name: devicemodelname,
    device_model_notes: devicemodelnotes,
    device_model_status: devicemodelstatus
  })
}

const deleteDeviceModel = (devicemodelId) => {
	return axios.delete(`${apiUrl}/v1/device-model/${devicemodelId}`, {})
}

const deviceModelsfilter =(searchkey,page, user_id) => {  
	let queryString = '';
	if(searchkey != '')
	{
	  queryString += `&device_model_name=${searchkey}`; 
	}  
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-model?apiAction=getModels${queryString}&limit=${config.devicemodels.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-model?apiAction=getModels${queryString}&limit=${config.devicemodels.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

export const devicemodelService = {
  getDeviceModels,
  createDeviceModel,
  updateDeviceModelDetails,
  deleteDeviceModel,
  getDeviceModelsAll,
  deviceModelsfilter
}