import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getGroups = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_group_name=${searchkey}`; 
  }

	if (user_id !== undefined) {
    return axios.get(`${apiUrl}/v1/device-groups?apiAction=getGroups${queryString}&limit=${config.device_groups.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=device_sort_id:asc`, {})
		.then(response => {
	    return response.data
	  })
  } else {
    return axios.get(`${apiUrl}/v1/device-groups?apiAction=getGroups${queryString}&limit=${config.device_groups.resultsPerPage}&page=${page}&sortBy=device_sort_id:asc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllGroups = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/device-groups/all?created_by=${user_id}`).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/device-groups/all`).then((response) => {
			return response.data
		})
	}
}

const createGroup = ( created_by, device_group_name, device_sort_id, device_group_notes, status ) => {
  return axios.post(`${apiUrl}/v1/device-groups`, {
    device_group_name: device_group_name,
    device_sort_id: device_sort_id,
    device_group_notes: device_group_notes,
    status: status,
    created_by: created_by
  })
}

const updateGroup = ( device_group_id, device_group_name, device_sort_id, device_group_notes, status ) => {
  return axios.patch(`${apiUrl}/v1/device-groups/${device_group_id}`, {
    device_group_name: device_group_name,
    device_sort_id: device_sort_id,
    device_group_notes: device_group_notes,
    status: status
  })
}

const deleteGroup = ( device_group_id ) => {
  return axios.delete(`${apiUrl}/v1/device-groups/${device_group_id}`)
}

const groupFilter = ( searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_group_name=${searchkey}`; 
  }
  if (user_id !== undefined) {
    return axios.get(`${apiUrl}/v1/device-groups?apiAction=getGroups${queryString}&limit=${config.device_groups.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-groups?apiAction=getGroups${queryString}&limit=${config.device_groups.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const groupService = {
  getGroups,
  getAllGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  groupFilter
}