

import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const createUserFeedback = ( created_by, tittle, message,feedback_file ) => {

  console.log("file",feedback_file)
  var formData = new FormData();  
  formData.append('send_file', feedback_file);
  formData.append('created_by',  created_by);
  formData.append('tittle', tittle);
  formData.append('message', message);
    return axios.post(`${apiUrl}/v1/feedback/user-feedback`,formData )
  }


  const getUserFeedback = (page, user_id) => {
	if(user_id !== undefined){
   return axios.get(`${apiUrl}/v1/feedback/user-feedback?limit=${config.feedback.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=createdAt:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
   return axios.get(`${apiUrl}/v1/feedback/user-feedback?limit=${config.feedback.resultsPerPage}&page=${page}&sortBy=createdAt:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const update_viewd_feedback = (  ) => {
  return axios.patch(`${apiUrl}/v1/feedback/user-feedback/update-view-feedback`, {})
}


const sendResponse = ( feedbackID,response ) => {
  return axios.post(`${apiUrl}/v1/feedback/user-feedback/response`, {
    response: response,
    feedbackID:feedbackID
  })
}

const getNewFeedback = ( ) => {
  return axios.get(`${apiUrl}/v1/feedback/user-feedback/new-feedback`)
}

  export const feedbackservice = {
    createUserFeedback,
    getUserFeedback,
    sendResponse,
    update_viewd_feedback,
    getNewFeedback
  }