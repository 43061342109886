import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import axios from 'axios'
import { config } from '../assets/config/config'
import ThemedSuspense from '../components/ThemedSuspense'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css';

const apiUrl = config.api.url

// create context
export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const [user, setUser] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [is_verified, setIsVerified] = useState(false)
  const [otp_status, setOtpStatus] = useState(null)
  const [active_email, setActiveEmail] = useState(null)
  const [RoleuserAccessRights, setRoleuserAccessRights] = useState(null)
  const [systemSettings, setsystemSettings] = useState(null)
  
  const notyf = new Notyf(
    {
      position: {
        x: 'right',
        y: 'bottom'
      },
      duration: 5000,
      ripple: false,
      dismissible: true
    }
  );

  const refreshTokens = useCallback(
    () => {
      
      return axios.post(`${apiUrl}/v1/auth/refresh-tokens`, {})
        .then(response => {
          setRoleuserAccessRights(response.data.accessRights)
          setsystemSettings(response.data.systemSettings)
          setAccessToken(response.data.token)
          setUser(response.data.user)
          return response
        })
        .catch(error => {
          setUser(null)
          setAccessToken(null)
          setIsVerified(false)
          return error
        })
    },
    []
  )

  const roleUserAccessRights = async (userData) => {
    if (userData.role == 'roleuser')
      
    return axios.post(`${apiUrl}/v1/role-users/role`, { roleID: userData.id })
      .then(response => {
        
        setRoleuserAccessRights(response.data)
      })
  }


  const startSilentRefresh = useCallback(
    () => {
      if (accessToken) {
        const tokenExpires = moment(accessToken.expires)
        
        const tokenMaxAge = tokenExpires.diff(moment().add(1, 'minutes'))
        

        setTimeout(() => {
          refreshTokens()
        }, tokenMaxAge)
      }
    },
    [accessToken, refreshTokens]
  )

  const syncLogout = (event) => {
    if (event.key === 'logout') {
      setAccessToken(null)
      setUser(null)
    }
  }

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        config.credentials = 'include'
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(interceptorId);
    }
  }, [accessToken])

  useEffect(() => {
    refreshTokens()
      .then(response => {
        setLoaded(true)
      })
  }, [refreshTokens])

  useEffect(() => {
    startSilentRefresh()
  }, [accessToken, startSilentRefresh])

  useEffect(() => {
    window.addEventListener('storage', syncLogout)
    return function cleanup() {
      window.removeEventListener('storage', syncLogout)
    }
  }, [])

  const value = useMemo(
    () => {
      const register = (username, company_name, phone_number, email, password) => {
        return axios.post(`${apiUrl}/v1/auth/register`, {
          name: username,
          company_name: company_name,
          phone_number: phone_number,
          email: email,
          password: password
        })
          .then(response => {
            setAccessToken(response.data.token)
            setUser(response.data.user)
            startSilentRefresh()
          })
      }

      const sendOTP = (email) => {
        return axios.post(`${apiUrl}/v1/auth/send-otp`, {
          email: email,
        })
        .then(response => {
          response.data.httpCode === 200 && notyf.success(response.data.message)
          setOtpStatus(response.data.message)
        })
        .catch(error => {
          notyf.error(error.response.data.message)
        })
      }

      const verifyOTP = (otp, email) => {
        return axios.post(`${apiUrl}/v1/auth/verify-otp`, {
          email: email,
          otp: otp,
        })
        .then(response => {
          setIsVerified(true)
          setOtpStatus(response.data.message)
          setActiveEmail(email)
        })
      }

      const login = (email, password) => {
        return axios.post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password
        })
          .then(response => {
            setRoleuserAccessRights(response.data.accessRights)
          setsystemSettings(response.data.systemSettings)
            setAccessToken(response.data.token)
            setUser(response.data.user)
            startSilentRefresh()
          })
      }

      const facebookAuth = () => {
        window.location.href = `${apiUrl}/v1/auth/facebook`;
      }

      const googleAuth = () => {
        window.location.href = `${apiUrl}/v1/auth/google`;
      }

      const logout = () => {
        setAccessToken(null)
        setUser(null)
        return axios.post(`${apiUrl}/v1/auth/logout`, {})
          .then(response => {
            window.localStorage.setItem('logout', moment())
          })
          .catch(err => { })
      }

      const forgotPassword = (email) => {
        return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
          email: email
        })
      }

      const resetPassword = (password, resetToken) => {
        return axios.post(`${apiUrl}/v1/auth/reset-password?token=${resetToken}`, {
          password: password
        })
      }

      const verifyEmail = (emailVerificationToken) => {
        return axios.post(`${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`, {})
      }

      return ({
        user,
        RoleuserAccessRights,
        systemSettings,
        setUser,
        is_verified,
        setIsVerified,
        otp_status,
        setOtpStatus,
        active_email,
        setActiveEmail,
        register,
        sendOTP,
        verifyOTP,
        login,
        facebookAuth,
        googleAuth,
        logout,
        forgotPassword,
        resetPassword,
        verifyEmail,
               
      })
    },
    [user,systemSettings,RoleuserAccessRights, is_verified, otp_status, active_email, startSilentRefresh]
  )

  if (!isLoaded) {
    return <ThemedSuspense />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
