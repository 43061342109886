import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getDevices = (searchkey, locationfilter, groupfilter, modelfilter, projectfilter, page,created_by) => {

  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_id=${searchkey}&device_name=${searchkey}`; 
  }
  if(locationfilter!='')
  {
    queryString += `&device_location_id=${locationfilter}`; 
  }
  if(groupfilter !='')
  {
    queryString += `&device_group_id=${groupfilter}`; 
  }
  if(modelfilter !='')
  {
    queryString += `&device_model_id=${modelfilter}`; 
  }
  if(projectfilter !='')
  {
    queryString += `&device_project_id=${projectfilter}`; 
  }

	if(created_by !== undefined){
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevices${queryString}&limit=${config.device.resultsPerPage}&page=${page}&created_by=${created_by}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevices${queryString}&limit=${config.device.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }

}

const getAllDeviceByUser = ( user_id ) => {
  if (user_id !== undefined) {
    return axios.get(`${apiUrl}/v1/device-management/device-by-user?created_by=${user_id}`, {})
      .then(response => {
        return response.data
      })
  } else {
    return axios.get(`${apiUrl}/v1/device-management/device-by-user`, {})
      .then(response => {
        return response.data
      })
  }
} 


const getAllDeviceByModel = ( user_id,model_id ) => {
    return axios.get(`${apiUrl}/v1/device-management/get-by-device_model?created_by=${user_id ?user_id : ''}&device_model_id=${model_id ? model_id : ''}`, {})
      .then(response => {
        return response.data
      })
} 

const getDeviceByID = (id) => {
	return axios.get(`${apiUrl}/v1/device-management/${id}`, {}).then((response) => {
		return response.data
	})
}

const createDevice = ( created_by, device_name, device_id, device_model_id, device_group_id, device_project_id, device_location_id, device_data_interval, device_notes, device_calibration_date, next_calibration_date, device_active_status ) => {
  return axios.post(`${apiUrl}/v1/device-management`, {
    device_name: device_name,
    device_id: device_id,
    device_model_id: device_model_id,
    device_group_id: device_group_id,
    device_project_id: device_project_id,
    device_location_id: device_location_id,
    device_data_interval: device_data_interval,
    device_notes: device_notes,
    device_calibration_date: device_calibration_date,
    next_calibration_date: next_calibration_date,
    device_active_status: device_active_status,
    created_by: created_by,
  })
}

const updateDevice = ( deviceid, device_name, device_id, device_model_id, device_group_id, device_project_id, device_location_id, device_data_interval, device_notes, device_calibration_date, next_calibration_date, device_active_status ) => {
  return axios.patch(`${apiUrl}/v1/device-management/${deviceid}`, {
    device_name: device_name,
    device_id: device_id,
    device_model_id: device_model_id,
    device_group_id: device_group_id,
    device_project_id: device_project_id,
    device_location_id: device_location_id,
    device_data_interval: device_data_interval,
    device_notes: device_notes,
    device_calibration_date: device_calibration_date,
    next_calibration_date: next_calibration_date,
    device_active_status: device_active_status,
    updated_at: new Date()
  })
}

const deleteDevice = ( deviceid ) => {
  return axios.delete(`${apiUrl}/v1/device-management/${deviceid}`)
}


const deviceFilter = ( searchkey,locationfilter,groupfilter,modelfilter,projectfilter, page,user_id, created_by) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_id=${searchkey}&device_name=${searchkey}`; 
  }
  if(locationfilter!='')
  {
    queryString += `&device_location_id=${locationfilter}`; 
  }
  if(groupfilter !='')
  {
    queryString += `&device_group_id=${groupfilter}`; 
  }
  if(modelfilter !='')
  {
    queryString += `&device_model_id=${modelfilter}`; 
  }
  if(projectfilter !='')
  {
    queryString += `&device_project_id=${projectfilter}`; 
  }

  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevices${queryString}&limit=${config.device.resultsPerPage}&page=${page}&user_id=${created_by}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevices${queryString}&limit=${config.device.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

const getOfflineDevices = (page,created_by,device_location) => {
	if(created_by !== undefined){
    return axios.get(`${apiUrl}/v1/device-management?limit=${config.device.resultsPerPage}&page=${page}&created_by=${created_by}&device_location=${device_location}&device_status=Offline`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-management?limit=${config.device.resultsPerPage}&page=${page}&device_status=Offline`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const deviceOfflineFilter = ( searchkey,locationfilter,groupfilter,modelfilter,projectfilter, page,user_id, created_by) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-management?device_id=${searchkey}&device_name=${searchkey}&device_location_id=${locationfilter}&device_group_id=${groupfilter}&device_model_id=${modelfilter}&device_project_id=${projectfilter}&limit=${config.device.resultsPerPage}&page=${page}&user_id=${created_by}&created_by=${user_id}&device_status=Offline`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-management?device_id=${searchkey}&device_name=${searchkey}&device_location_id=${locationfilter}&device_group_id=${groupfilter}&device_model_id=${modelfilter}&device_project_id=${projectfilter}&limit=${config.device.resultsPerPage}&page=${page}&device_status=Offline`)
    .then(response => {
      return response.data
    })
  }
}

const setDeviceAlarm = (deviceid, temperature_alarm, humidity_alarm, voltage_alarm, security_alarm) => {
  return axios.post(`${apiUrl}/v1/device-management/${deviceid}`, {
    temperature_alarm: temperature_alarm && temperature_alarm !== undefined && temperature_alarm !== null ? temperature_alarm : null,
    humidity_alarm: humidity_alarm && humidity_alarm !== undefined && humidity_alarm !== null ? humidity_alarm : null,
    voltage_alarm: voltage_alarm && voltage_alarm !== undefined && voltage_alarm !== null ? voltage_alarm : null,
    security_alarm: security_alarm && security_alarm !== undefined && security_alarm !== null ? security_alarm : null,
  })
}

const getDeviceAll = (  ) => {
  return axios.get(`${apiUrl}/v1/device-management/getDevicesAll`, )
.then(response => {
  return response.data
})
}



const getDeviceByDeviceID = ( deviceid ) => {
  return axios.post(`${apiUrl}/v1/device-management/getDeviceByID`,{device_id:deviceid} )
.then(response => {
  return response.data
})
}

const resetAlarm = ( deviceid ) => {

  return axios.post(`${apiUrl}/v1/device-management/resetalarm`,{device_id:deviceid} )
  .then(response => {
    return response.data
  })
}


const getAddDeviceData = async (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/device-management/getAddDeviceData?created_by=${user_id}`).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/device-management/getAddDeviceData`).then((response) => {
			return response.data
		})
	}
}

const getDeviceCommand = ( searchkey, page, created_by) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_id=${searchkey}&device_name=${searchkey}`; 
  }

  if(created_by !== undefined){
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevicecommand${queryString}&limit=${config.device.resultsPerPage}&page=${page}&created_by=${created_by}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/device-management?apiAction=getDevicecommand${queryString}&limit=${config.device.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const deviceService = {
	getDevices,
	getAllDeviceByUser,
  getDeviceByID,
	createDevice,
	updateDevice,
	deleteDevice,
  deviceFilter,
  getOfflineDevices,
  deviceOfflineFilter,
	setDeviceAlarm,
	getDeviceAll,
	getDeviceByDeviceID,
	resetAlarm,
  getAddDeviceData,
  getAllDeviceByModel,
  getDeviceCommand
}