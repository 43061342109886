import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getSystemSettings = async (user_id) => {
  return axios.get(`${apiUrl}/v1/system-settings?created_by=${user_id}`, {}).then((response) => {
    return response.data
  })
}

const getSystemSettingsOptions = async () => {
  return axios.get(`${apiUrl}/v1/system-settings/options`, {})
  .then((response) => {
    return response.data
  })
}

const createSystemSettings = (user_id, storage_interval, temperature_unit, shared_data, language, unique_id, time_zone) => {
  return axios.post(`${apiUrl}/v1/system-settings`, {
    storage_interval: storage_interval,
    temperature_unit: temperature_unit,
    shared_data: shared_data ? '1' : '0',
    language: language,
    unique_id: unique_id,
    time_zone: time_zone,
    created_by: user_id,
  })
}

const updateSystemSettings = (system_setting_id, storage_interval, temperature_unit, shared_data, language, unique_id, time_zone) => {
  return axios.patch(`${apiUrl}/v1/system-settings/${system_setting_id}`, {
    storage_interval: storage_interval,
    temperature_unit: temperature_unit,
    shared_data: shared_data ? '1' : '0',
    language: language,
    unique_id: unique_id,
    time_zone: time_zone,
  })
}

export const systemsettingsService = {
  getSystemSettings,
  getSystemSettingsOptions,
  createSystemSettings,
  updateSystemSettings,
}
