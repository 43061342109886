import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getTemplates = (page, user_id) => {
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/email-notifications/templates?limit=${config.email_templates.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/email-notifications/templates?limit=${config.email_templates.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const emailTemplateFilter = (searchkey,page, user_id) => {
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/email-notifications/templates?ref_name=${searchkey}&limit=${config.email_templates.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/email-notifications/templates?ref_name=${searchkey}&limit=${config.email_templates.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getEmailTemplateByID = ( template_id ) => {
  return axios.get(`${apiUrl}/v1/email-notifications/templates/${template_id}`, {})
  .then(response => {
    return response.data
  })
}

const getAllTemplates = () => {
  return axios.get(`${apiUrl}/v1/email-notifications/templates`)
  .then(response => {
    return response.data
  })
}

const createTemplate = ( ref_name, from_name, from_email, reply_to, subject, content, status ) => {
  return axios.post(`${apiUrl}/v1/email-notifications/templates`, {
    ref_name: ref_name,
    from_name: from_name,
    from_email: from_email,
    reply_to: reply_to,
    subject: subject,
    content: content,
    status: status,
  })
}

const updateTemplate = ( template_id, ref_name, from_name, from_email, reply_to, subject, content, status ) => {
  return axios.patch(`${apiUrl}/v1/email-notifications/templates/${template_id}`, {
    ref_name: ref_name,
    from_name: from_name,
    from_email: from_email,
    reply_to: reply_to,
    subject: subject,
    content: content,
    status: status,
    updated_at: new Date()
  })
}

const deleteTemplate = ( template_id ) => {
  return axios.delete(`${apiUrl}/v1/email-notifications/templates/${template_id}`)
}

export const emailtemplateService = {
  getTemplates,
  getEmailTemplateByID,
  getAllTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  emailTemplateFilter
}