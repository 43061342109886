import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getAllDeviceCommandByDeviceId = (device_id) => {
	return axios.get(`${apiUrl}/v1/command-to-receiver-device/${device_id}`)
}

const createDeviceCommand = (user_id, device_id, device_command) => {
	return axios.post(`${apiUrl}/v1/command-to-receiver-device/${device_id}`, {
		command: device_command,
		command_by: user_id,
	})
}

export const receiverdevice_commandService = {
	getAllDeviceCommandByDeviceId,
	createDeviceCommand,
}
