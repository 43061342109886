import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getRoleUsers = (page, user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/role-users?limit=${config.roles.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/role-users?limit=${config.roles.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getRolebyUserID = ( user_id ) => {
  return axios.get(`${apiUrl}/v1/role-users/role/${user_id}`, {})
  .then(response => {
    return response.data
  })
}
const getRoleUserByID = ( role_user_id ) => {
  return axios.get(`${apiUrl}/v1/role-users/${role_user_id}`, {})
  .then(response => {
    return response.data
  })
}


const getComponentAccessRightsByRole = ( moduleName, roleID) => {
  return axios.post(`${apiUrl}/v1/role-users/role/`, {moduleName:moduleName,roleID:roleID})
  .then(response => {
    return response.data
  })
}

const createRoleUser = ( created_by, role, user_id, status ) => {
  return axios.post(`${apiUrl}/v1/role-users`, {
    role: role,
    user_id: user_id,
    status: status,
    created_by: created_by
  })  
}

const updateRoleUser = ( role_user_id, role, user_id, status ) => {
  return axios.patch(`${apiUrl}/v1/role-users/${role_user_id}`, {
    role: role,
    user_id: user_id,
    status: status,
    updated_at: new Date()
  })
}

const deleteRoleUser = ( role_user_id ) => {
  return axios.delete(`${apiUrl}/v1/role-users/${role_user_id}`)
}

const roleusersFilter = ( role_name, page, user_id) => {
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/role-users?limit=${config.roles.resultsPerPage}&page=${page}&role_name=${role_name}&created_by=${user_id}`, {})
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/role-users?limit=${config.roles.resultsPerPage}&page=${page}&role_name=${role_name}`, {})
    .then(response => {
      return response.data
    })
  }
}

export const role_usersService = {
  getRoleUsers,
  getRoleUserByID,
  createRoleUser,
  updateRoleUser,
  deleteRoleUser,
  roleusersFilter,getRolebyUserID,getComponentAccessRightsByRole
}