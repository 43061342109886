import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getAlarmTemplate = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/AlarmTemplate?apiAction=getAlarmTemplate${queryString}&limit=${config.device.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/AlarmTemplate?apiAction=getAlarmTemplate${queryString}&limit=${config.device.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const createAlarmTemplate = ( user_id, alarm_name, device_id, alarm_type, warning,  excursion,fromArray ) => {
  console.log("from",fromArray)
  return axios.post(`${apiUrl}/v1/AlarmTemplate/createtemplate`, {
    alarm_name: alarm_name,
    device_id: device_id,
    alarm_type: alarm_type,
    warning: warning,
    excursion: excursion,
    occur_event_array: fromArray,
    created_by: user_id,
  })
}

const UpdateAlarmTemplate = ( id, alarm_name, device_id, alarm_type, warning,  excursion,fromArray  ) => {
    console.log("tempid",id)
  return axios.patch(`${apiUrl}/v1/AlarmTemplate/${id}`, {
    alarm_name: alarm_name,
    device_id: device_id,
    alarm_type: alarm_type,
    warning: warning,
    excursion: excursion,
    occur_event_array:fromArray,
  
  })
}


const getAlarmTemplateByid = ( id ) => {
  console.log("tempid",id)
return axios.post(`${apiUrl}/v1/AlarmTemplate/getById`, {
  Alarm_template_id: id,
})
}

const deleteAlarmTemplate = ( id ) => {
  return axios.delete(`${apiUrl}/v1/AlarmTemplate/${id}`)
}


const alarmTemplateFilter = (searchkey,page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/AlarmTemplate?apiAction=getAlarmTemplate${queryString}&limit=${config.device.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/AlarmTemplate?apiAction=getAlarmTemplate${queryString}&limit=${config.device.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}


// const deviceFilter = ( searchkey, page, user_id) => {
//   if(user_id !== undefined){
//     return axios.get(`${apiUrl}/v1/device-management?device_name=${searchkey}&limit=${config.device.resultsPerPage}&page=${page}&created_by=${user_id}`)
//     .then(response => {
//       return response.data
//     })
//   }else{
//     return axios.get(`${apiUrl}/v1/device-management?device_name=${searchkey}&limit=${config.device.resultsPerPage}&page=${page}`)
//     .then(response => {
//       return response.data
//     })
//   }
// }

// const setDeviceAlarm = ( deviceid, temperature_alarm, humidity_alarm, voltage_alarm, security_alarm ) => {
//   return axios.post(`${apiUrl}/v1/device-management/${deviceid}`, {
//     temperature_alarm: temperature_alarm,
//     humidity_alarm: humidity_alarm,
//     voltage_alarm: voltage_alarm,
//     security_alarm: security_alarm,
//   })
// }

export const AlarmTemplateSerive = {
  getAlarmTemplate,
  createAlarmTemplate,
  UpdateAlarmTemplate,
  getAlarmTemplateByid,
  deleteAlarmTemplate,
  alarmTemplateFilter,
//   deviceFilter,
//   setDeviceAlarm
}