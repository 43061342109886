import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getVoltage = (searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/voltage?apiAction=getVoltage${queryString}&limit=${config.voltage_as.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/voltage?apiAction=getVoltage${queryString}&limit=${config.voltage_as.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getVoltageById = (voltage_as_id) => {
	return axios.get(`${apiUrl}/v1/alarm-settings/voltage/${voltage_as_id}`).then((response) => {
		return response.data
	})
}

const getAllVoltage = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/alarm-settings/voltage/all?created_by=${user_id}`, {}).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/alarm-settings/voltage/all`).then((response) => {
			return response.data
		})
	}
}

const createVoltage = ( created_by, alarm_name, low_voltage, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.post(`${apiUrl}/v1/alarm-settings/voltage`, {
    alarm_name: alarm_name,
    low_voltage: low_voltage,
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    created_by: created_by,
    effective_days: effective_days,

  })
}

const updateVoltage = ( voltage_as_id, alarm_name, low_voltage, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status ,effective_days ) => {
  return axios.patch(`${apiUrl}/v1/alarm-settings/voltage/${voltage_as_id}`, {
    alarm_name: alarm_name,
    low_voltage: low_voltage,
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    updated_at: new Date(),
    effective_days: effective_days,

  })
}

const deleteVoltage = ( voltage_as_id ) => {
  return axios.delete(`${apiUrl}/v1/alarm-settings/voltage/${voltage_as_id}`)
}

const voltageFilter = ( searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }     
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/voltage?apiAction=getVoltage${queryString}&limit=${config.voltage_as.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/voltage?apiAction=getVoltage${queryString}&limit=${config.voltage_as.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const voltageService = {
  getVoltage,
  getAllVoltage,
  createVoltage,
  updateVoltage,
  deleteVoltage,
  voltageFilter,
  getVoltageById,
}