import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const createSMTPSettings = ( smtp_from_mail, smtp_host_name, smtp_user_name, smtp_password, smtp_port ) => {
  return axios.post(`${apiUrl}/v1/smtp-settings`, {
    smtp_from_mail: smtp_from_mail,
    smtp_host_name: smtp_host_name,
    smtp_user_name: smtp_user_name,
    smtp_password: smtp_password,
    smtp_port: smtp_port
  })
}

const updateSMTPSettings = ( smtpid, smtp_from_mail, smtp_host_name, smtp_user_name, smtp_password, smtp_port ) => {
	return axios.patch(`${apiUrl}/v1/smtp-settings/${smtpid}`, {
    smtp_from_mail: smtp_from_mail,
    smtp_host_name: smtp_host_name,
    smtp_user_name: smtp_user_name,
    smtp_password: smtp_password,
    smtp_port: smtp_port
  })
}

export const smtpsettingsService = {
  createSMTPSettings,
  updateSMTPSettings,
}