const Theme = {
	button: {
		icon: {
			small: 'h-4 w-4',
		},
		primary: {
			base: 'text-white bg-blue-500 border border-transparent',
			active: 'active:bg-blue-500 hover:bg-blue-600 focus:ring focus:ring-blue-300',
			disabled: 'opacity-50 cursor-not-allowed',
		},
		outline: {
			base: 'text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none',
			active: 'active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:ring focus:ring-gray-300',
			disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
		},
	},
}

export default Theme
