import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getSecurity = (searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  

	if(user_id !== undefined){
   return axios.get(`${apiUrl}/v1/alarm-settings/security?apiAction=getSecurity${queryString}&limit=${config.security_as.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
   return axios.get(`${apiUrl}/v1/alarm-settings/security?apiAction=getSecurity${queryString}&limit=${config.security_as.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllSecurity = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/alarm-settings/security/all?created_by=${user_id}`, {}).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/alarm-settings/security/all`).then((response) => {
			return response.data
		})
	}
}

const getSecurityById = (security_as_id) => {
	return axios.get(`${apiUrl}/v1/alarm-settings/security/${security_as_id}`).then((response) => {
		return response.data
	})
}

const createSecurity = ( created_by, alarm_name, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.post(`${apiUrl}/v1/alarm-settings/security`, {
    alarm_name: alarm_name,
    // offline_time: offline_time,
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    created_by: created_by,
    effective_days: effective_days,

  })
}

const updateSecurity = ( security_as_id, alarm_name, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.patch(`${apiUrl}/v1/alarm-settings/security/${security_as_id}`, {
    alarm_name: alarm_name,
    // offline_time: offline_time,
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    updated_at: new Date(),
    effective_days: effective_days,

  })
}

const deleteSecurity = ( security_as_id ) => {
  return axios.delete(`${apiUrl}/v1/alarm-settings/security/${security_as_id}`)
}

const securityFilter = ( searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }    
  if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/security?apiAction=getSecurity${queryString}&limit=${config.security_as.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/security?apiAction=getSecurity${queryString}&limit=${config.security_as.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const securityService = {
  getSecurity,
  getAllSecurity,
  createSecurity,
  updateSecurity,
  deleteSecurity,
  securityFilter,getSecurityById
}