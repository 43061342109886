import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getPushNotifications = (page) => {
	return axios.get(`${apiUrl}/v1/push-notifications?limit=${config.push_notifications.resultsPerPage}&page=${page}`, {}).then((response) => {
		return response.data
	})
}

const getPushNotificationsByUserEmail = (page, email) => {
	return axios
		.get(`${apiUrl}/v1/push-notifications/get-by-user-email?email=${email}&limit=${config.push_notifications.resultsPerPage}&page=${page}`, {})
		.then((response) => {
			return response.data
		})
}

const sendPushNotifications = (users, title, body) => {
	return axios.post(`${apiUrl}/v1/push-notifications`, {
		users: users,
		title: title,
		body: body,
	})
}

export const pushNotificationService = {
	getPushNotifications,
	getPushNotificationsByUserEmail,
	sendPushNotifications,
}
