const Joi = require('joi');

const envVarsSchema = Joi.object()
  .keys({
    NODE_ENV: Joi.string().valid('production', 'development', 'demo', 'test').required(),
    REACT_APP_API_URL: Joi.string().allow('').default('').description('backend server url'),
    REACT_APP_STRIPE_PUBLIC_KEY: Joi.string().required().description('Stripe public key'),
    REACT_APP_TINYMCE_KEY: Joi.string().description('TinyMCE key'),
    REACT_APP_USERS_PER_PAGE: Joi.number().default(10).description('number of users per page in users table'),
    REACT_APP_RECEIVERTYPE_PER_PAGE: Joi.number().default(10).description('number of receiver type per page in receiver type table'),
    REACT_APP_RECEIVERDEVICE_PER_PAGE: Joi.number().default(10).description('number of receiver device per page in receiver device table'),
    REACT_APP_PROJECTS_PER_PAGE: Joi.number().default(10).description('number of projects per page in projects table'),
    REACT_APP_DEVICEMODEL_PER_PAGE: Joi.number().default(10).description('number of device model per page in projects table'),
    REACT_APP_DEVICE_GROUPS_PER_PAGE: Joi.number().default(10).description('number of device groups per page in device groups table'),
    REACT_APP_TEMPERATURE_PER_PAGE: Joi.number().default(10).description('number of temperature alarm settings per page in temperature table'),
    REACT_APP_HUMIDITY_PER_PAGE: Joi.number().default(10).description('number of humidity alarm settings per page in humidity table'),
    REACT_APP_VOLTAGE_PER_PAGE: Joi.number().default(10).description('number of voltage alarm settings per page in voltage table'),
    REACT_APP_SECURITY_PER_PAGE: Joi.number().default(10).description('number of security alarm settings per page in security table'),
    REACT_APP_DEVICE_PER_PAGE: Joi.number().default(10).description('number of device per page in device table'),
    REACT_APP_DEVICE_LOCATION_PER_PAGE: Joi.number().default(10).description('number of device location per page in device location table'),
    REACT_APP_DEVICE_REPORTS_PER_PAGE: Joi.number().default(10).description('number of device reports per page in device reports table'),
    REACT_APP_NOTIFICATION_LIST_PER_PAGE: Joi.number().default(10).description('number of device reports per page in device reports table'),
    REACT_APP_CALIBRATION_LIST_PER_PAGE: Joi.number().default(10).description('number of device reports per page in device reports table'),
    REACT_APP_EMAIL_TEMPLATES_PER_PAGE: Joi.number().default(10).description('number of email templates per page in email templates table'),
    REACT_APP_EMAIL_NOTIFICATIONS_PER_PAGE: Joi.number().default(10).description('number of email notifications per page in email notifications table'),
    REACT_APP_PUSH_NOTIFICATIONS_PER_PAGE: Joi.number().default(10).description('number of push notifications per page in push notifications table'),
    REACT_APP_ALARM_REPORT_PER_PAGE: Joi.number().default(10).description('number of alarm reports per page in alarm reports table'),
    REACT_APP_USER_ROLES_PER_PAGE: Joi.number().default(10).description('number of user roles per page'),
    REACT_APP_FEEDBACK_PER_PAGE: Joi.number().default(10).description('number of user roles per page'),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}
console.log('envVars.NODE_ENV',envVars.NODE_ENV);
export const config = {
  api: {
    url: envVars.NODE_ENV === 'production' ? envVars.REACT_APP_API_URL : 'http://localhost:9090',
    // url: 'http://localhost:8080',
  },
  app: {
    url: envVars.NODE_ENV === 'production' ? envVars.REACT_APP_URL : 'http://localhost:3000',
  },
  stripe: {
    publicKey: envVars.REACT_APP_STRIPE_PUBLIC_KEY,
  },
  tinymce: {
    apiKey: envVars.REACT_APP_TINYMCE_KEY,
  },
  users: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  receivertypes: {
  	resultsPerPage: envVars.REACT_APP_RECEIVERTYPE_PER_PAGE,
  },
  receiverdevices: {
  	resultsPerPage: envVars.REACT_APP_RECEIVERDEVICE_PER_PAGE,
  },
  projects: {
  	resultsPerPage: envVars.REACT_APP_PROJECTS_PER_PAGE,
  },
  devicemodels: {
  	resultsPerPage: envVars.REACT_APP_DEVICEMODEL_PER_PAGE,
  },
  device_groups: {
  	resultsPerPage: envVars.REACT_APP_DEVICE_GROUPS_PER_PAGE,
  },
  temperature_as: {
  	resultsPerPage: envVars.REACT_APP_TEMPERATURE_PER_PAGE,
  },
  humidity_as: {
  	resultsPerPage: envVars.REACT_APP_HUMIDITY_PER_PAGE,
  },
  voltage_as: {
  	resultsPerPage: envVars.REACT_APP_VOLTAGE_PER_PAGE,
  },
  security_as: {
  	resultsPerPage: envVars.REACT_APP_SECURITY_PER_PAGE,
  },
  device: {
  	resultsPerPage: envVars.REACT_APP_DEVICE_PER_PAGE,
  },
  device_location: {
  	resultsPerPage: envVars.REACT_APP_DEVICE_LOCATION_PER_PAGE,
  },
  devicereports: {
  	resultsPerPage: envVars.REACT_APP_DEVICE_REPORTS_PER_PAGE,
  },
  notificationlist: {
  	resultsPerPage: envVars.REACT_APP_NOTIFICATION_LIST_PER_PAGE,
  },
  calibrationlist: {
  	resultsPerPage: envVars.REACT_APP_CALIBRATION_LIST_PER_PAGE,
  },
  email_templates: {
  	resultsPerPage: envVars.REACT_APP_EMAIL_TEMPLATES_PER_PAGE,
  },
  email_notifications: {
  	resultsPerPage: envVars.REACT_APP_EMAIL_NOTIFICATIONS_PER_PAGE,
  },
  push_notifications: {
  	resultsPerPage: envVars.REACT_APP_PUSH_NOTIFICATIONS_PER_PAGE,
  },
  alarmreports: {
  	resultsPerPage: envVars.REACT_APP_ALARM_REPORT_PER_PAGE,
  },
  roles: {
  	resultsPerPage: envVars.REACT_APP_USER_ROLES_PER_PAGE,
  },
  feedback: {
  	resultsPerPage: envVars.REACT_APP_FEEDBACK_PER_PAGE,
  },
};
