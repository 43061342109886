import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getHumidity = (searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  

	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-settings/humidity?apiAction=getHumidity${queryString}&limit=${config.humidity_as.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-settings/humidity?apiAction=getHumidity${queryString}&limit=${config.humidity_as.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllHumidity = (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/alarm-settings/humidity/all?created_by=${user_id}`, {}).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/alarm-settings/humidity/all`).then((response) => {
			return response.data
		})
	}
}

const getHumidityById = (humidity_as_id) => {
	return axios.get(`${apiUrl}/v1/alarm-settings/humidity/${humidity_as_id}`).then((response) => {
		return response.data
	})
}

const createHumidity = ( created_by, alarm_name, low_humidity_warn_threshold, high_humidity_warn_threshold, low_humidity_threshold, high_humidity_threshold, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status,effective_days ) => {
  return axios.post(`${apiUrl}/v1/alarm-settings/humidity`, {
    alarm_name: alarm_name,
    low_humidity_warn_threshold: low_humidity_warn_threshold,
    high_humidity_warn_threshold: high_humidity_warn_threshold,
    low_humidity_threshold: low_humidity_threshold,
    high_humidity_threshold: high_humidity_threshold,
    
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    created_by: created_by,
    effective_days: effective_days,
  })
}

const updateHumidity = ( humidity_as_id, alarm_name, low_humidity_warn_threshold, high_humidity_warn_threshold, low_humidity_threshold, high_humidity_threshold, effective_date_from, effective_date_to, effective_date_status, effective_time_from, effective_time_to, effective_time_status ,effective_days) => {
  return axios.patch(`${apiUrl}/v1/alarm-settings/humidity/${humidity_as_id}`, {
    alarm_name: alarm_name,
    low_humidity_warn_threshold: low_humidity_warn_threshold,
    high_humidity_warn_threshold: high_humidity_warn_threshold,
    low_humidity_threshold: low_humidity_threshold,
    high_humidity_threshold: high_humidity_threshold,
    effective_date_from: effective_date_from,
    effective_date_to: effective_date_to,
    effective_date_status: effective_date_status ? "1" : "0",
    effective_time_from: effective_time_from,
    effective_time_to: effective_time_to,
    effective_time_status: effective_time_status ? "1" : "0",
    updated_at: new Date(),
    effective_days: effective_days,
  })
}

const deleteHumidity = ( humidity_as_id ) => {
  return axios.delete(`${apiUrl}/v1/alarm-settings/humidity/${humidity_as_id}`)
}

const humidityFilter = ( searchkey, date_from, date_to, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&alarm_name=${searchkey}`; 
  }
  if(date_from != '' )
  {
    queryString += `&effective_date_from=${date_from}`; 
  }
  if(date_to != '' )
  {
    queryString += `&effective_date_to=${date_to}`; 
  }  

  if (user_id !== undefined) {
    return axios.get(`${apiUrl}/v1/alarm-settings/humidity?apiAction=getHumidity${queryString}&limit=${config.humidity_as.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  } else {
    return axios.get(`${apiUrl}/v1/alarm-settings/humidity?apiAction=getHumidity${queryString}&limit=${config.humidity_as.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const humidityService = {
  getHumidity,
  getAllHumidity,
  createHumidity,
  updateHumidity,
  deleteHumidity,
  humidityFilter,getHumidityById
}