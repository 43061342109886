import axios from "axios";
import { config } from "../assets/config/config";
const apiUrl = config.api.url;

const createLogActivity = (user_id, logActivity, description) => {
  return axios.post(`${apiUrl}/v1/logActivity`, {
    user_id: user_id,
    logActivity: logActivity,
    description: description,
  });
};
const getAllLogsList = (page, limit, log_type) => {
  return axios
    .get(
      `${apiUrl}/v1/logs/all-logs-list?page=${page}&limit=${limit}&log_type=${log_type}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

export const logActivityService = {
  createLogActivity,
  getAllLogsList,
};
