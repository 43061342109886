import axios from 'axios'
import { config } from '../assets/config/config'
const apiUrl = config.api.url

const getLocations = (searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_location_name=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/device-locations?apiAction=getLocations${queryString}&limit=${config.device_location.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/device-locations?apiAction=getLocations${queryString}&limit=${config.device_location.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const getAllLocations = async (user_id) => {
	if (user_id !== undefined) {
		return axios.get(`${apiUrl}/v1/device-locations/all?created_by=${user_id}`).then((response) => {
			return response.data
		})
	} else {
		return axios.get(`${apiUrl}/v1/device-locations/all`).then((response) => {
			return response.data
		})
	}
}

const createLocation = ( created_by, device_location_name, device_location_address, status ) => {
  return axios.post(`${apiUrl}/v1/device-locations`, {
    device_location_name: device_location_name,
    device_location_address: device_location_address,
    status: status,
    created_by: created_by,
  })
}

const updateLocation = ( location_id, device_location_name, device_location_address, status ) => {
  return axios.patch(`${apiUrl}/v1/device-locations/${location_id}`, {
    device_location_name: device_location_name,
    device_location_address: device_location_address,
    status: status,
    updated_at: new Date()
  })
}

const deleteLocation = ( location_id ) => {
  return axios.delete(`${apiUrl}/v1/device-locations/${location_id}`)
}

const locationFilter = ( searchkey, page, user_id) => {
  let queryString = '';
  if(searchkey != '')
  {
    queryString += `&device_location_name=${searchkey}`; 
  }  
  if (user_id !== undefined) {
    return axios.get(`${apiUrl}/v1/device-locations?apiAction=getLocations${queryString}&limit=${config.device_location.resultsPerPage}&page=${page}&created_by=${user_id}`)
    .then(response => {
      return response.data
    })
  } else {
    return axios.get(`${apiUrl}/v1/device-locations?apiAction=getLocations${queryString}&limit=${config.device_location.resultsPerPage}&page=${page}`)
    .then(response => {
      return response.data
    })
  }
}

export const devicelocationService = {
  getLocations,
  getAllLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  locationFilter
}