import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCurrentAlarmReports = (searchkey, alarmType,page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&location=${searchkey}`; 
  }

	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getCurrentAlarm${queryString}&active_flag=${0}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getCurrentAlarm${queryString}&active_flag=${0}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const getHistoryAlarmReports = (searchkey, alarmType, page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&location=${searchkey}`; 
  }  
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getHistoryAlarm${queryString}&active_flag=${1}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getHistoryAlarm${queryString}&active_flag=${1}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const currentAlarmReportsFilter = (alarmType,searchkey,page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&location=${searchkey}`; 
  }  
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getCurrentAlarm${queryString}&active_flag=${0}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getCurrentAlarm${queryString}&active_flag=${0}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}


const histroyAlarmReportsFilter = (alarmType,searchkey,page, user_id) => {
  let queryString = '';
  if(searchkey != '' )
  {
    queryString += `&location=${searchkey}`; 
  }
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getHistoryAlarm${queryString}&active_flag=${1}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&created_by=${user_id}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/alarm-reports?apiAction=getHistoryAlarm${queryString}&active_flag=${1}&alarm_type=${alarmType}&limit=${config.alarmreports.resultsPerPage}&page=${page}&sortBy=logs_datetime:desc`, {})
		.then(response => {
	    return response.data
	  })
  }
}

const acknowledgeAlarmReport = (alarm_report_id, is_acknowledged, acknowledged_on, user_id) => {
	return axios.post(`${apiUrl}/v1/alarm-reports/${alarm_report_id}`, {
    is_acknowledged: is_acknowledged,
    acknowledged_on: acknowledged_on,
    acknowledged_by: user_id
  })
}

const commentAlarmReport = (alarm_report_id, user_id, comment) => {
  return axios.patch(`${apiUrl}/v1/alarm-reports/${alarm_report_id}`, {
    comment: comment,
    comment_on: new Date(),
    comment_by: user_id
  })
}


export const alarmreportService = {
  getCurrentAlarmReports,
  acknowledgeAlarmReport,
  commentAlarmReport,
  currentAlarmReportsFilter,
  histroyAlarmReportsFilter,
  getHistoryAlarmReports
}